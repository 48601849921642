<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Transfer-out-items'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link> <span v-if="!loading"> {{ $t('listTransferOrder') }}  {{ dataTransfer.transfer_id }} <TransferStatus :status="+dataTransfer.transfer_status_id" /> </span>

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="dataTransfer.transfer_status_id=='3'"
          class="d-none d-md-block"
          @click="print"
        >
          <v-icon
            class="me-1"
          >
            {{ icons.mdiPrinter }}
          </v-icon>
          <span>{{ $t('print_drug_equipment_transfer') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          fab
          :disabled="dataTransfer.transfer_status_id=='3'"
          icon
          outlined
          class="d-block d-md-none"
          @click="print"
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-5">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p> <span class="font-weight-semibold"> {{ $t('transfer_to') }}  : </span>{{ dataTransfer.shop_id_name_to }} ({{ dataTransfer.shop_id_to }})</p>
          <p> <span class="font-weight-semibold"> {{ $t('transfer_name') }}  : </span>{{ dataTransfer.user_fullname }}</p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p> <span class="font-weight-semibold"> {{ $t('total_price') }}  : </span>{{ dataTransfer.transfer_price }}</p>
          <p> <span class="font-weight-semibold"> {{ $t('inspector') }}  : </span>{{ dataTransfer.user_fullname_to }}</p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p> <span class="font-weight-semibold"> {{ $t('transfer_date') }}  : </span> <ConverseDate :date="dataTransfer.transfer_create" /></p>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        disable-sort
        hide-default-footer
        :no-data-text="loading?$t('data_loading'):$t('no_information')"
      >
        <template v-slot:[`item.transfer_id_pri`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.transferorder_drugorder_expdate`]="{ item }">
          <ConverseDate :date="item.transferorder_drugorder_expdate" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiArrowLeftBold, mdiPrinter } from '@mdi/js'
import router from '@/router'
import transferOut from '@/api/drugTransfer/transferOut'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { i18n } from '@/plugins/i18n'
import TransferStatus from '@/components/basicComponents/TransferStatus.vue'

export default {
  components: {
    ConverseDate,
    TransferStatus,
  },
  setup() {
    const dataTransfer = ref({})
    const loading = ref(true)
    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      {
        text: '#',
        align: 'start',
        sortable: false,
        value: 'transfer_id_pri',
        width: 50,
      },
      {
        text: i18n.t('drug_code'),
        value: 'transferorder_drug_id',

      },
      {
        text: i18n.t('transfer_list'),
        value: 'transferorder_drug_name',

      },
      {
        text: i18n.t('expired_date'),
        value: 'transferorder_drugorder_expdate',

      },
      {
        text: i18n.t('total_price'),
        value: 'transferorder_total',

      },
      {
        text: i18n.t('quantity'),
        value: 'transferorder_amount',

      },
      {
        text: i18n.t('unit'),
        value: 'transferorder_drug_unit',

      },
      {
        text: i18n.t('price'),
        value: 'transferorder_cost',

      },
      {
        text: i18n.t('total_price'),
        value: 'transferorder_total',

      },
    ])
    const { transferOutGet } = transferOut

    transferOutGet(router.currentRoute.params.id).then(res => {
      dataTransfer.value = res
      dataTableList.value = res.transferorder
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    const print = () => {
      const routeData = router.resolve({
        name: 'printDrugTransferOut',
        query: {
          id: String([router.currentRoute.params.id]),
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      dataTransfer,
      loading,
      dataTableList,
      options,
      columns,
      print,
      icons: {
        mdiArrowLeftBold,
        mdiPrinter,
      },
    }
  },
}
</script>
